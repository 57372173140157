import { useContext, computed } from "@nuxtjs/composition-api";

export function usePopularProfessionTasks() {
  const { store, $helpers, localePath, i18n } = useContext();

  const professionTasks = store.getters["data/popularProfessionTasks"];

  const professionTaskMenuItems = computed(() => {
    const items = professionTasks.map((professionTask) => ({
      id: `profession-task-${professionTask.seoName}`,
      text: $helpers.capitalize(professionTask.name),
      icon: "arrow-right",
      to: localePath({
        name: "prices",
        params: { professionTask: professionTask.seoName },
      }),
    }));
    items.unshift({
      id: "profession-task-all",
      icon: "list-ol",
      text: i18n.t("navbar.professionTasks.all"),
      to: localePath({ name: "pricesPage" }),
    });

    return items;
  });

  return { professionTasks, professionTaskMenuItems };
}
